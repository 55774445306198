import React, { useEffect } from "react";
import FormPageWrapper from "../components/FormPageWrapper";
import Layout from "../components/Layout";
import { HeadProps, navigate } from "gatsby";
import toast from "react-hot-toast";
import ButtonPrimary from "../components/ButtonPrimary";
import { useCheckoutSubmitEmailMutation } from "../features/checkout/checkoutApiSlice";
import { useAppDispatch } from "../app/hooks";
import { emailStepCompleted } from "../features/checkout/checkoutSlice";
import { handleErrorResponse } from "../app/apiSlice";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../components/FormErrorMessage";
import Seo from "../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface SignUpFormValues {
  email: string;
  marketingConsent: boolean;
}

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormValues>();

  const [emailTrigger] = useCheckoutSubmitEmailMutation();
  const dispatch = useAppDispatch();
  const { data: currentUser, isFetching } = useCurrentUserQuery();

  // Authenticated users redirected to /sign-up/checkout.
  useEffect(() => {
    if (currentUser?.is_authenticated && isFetching === false) {
      if (typeof gtag === "function") {
        gtag("event", "exception", {
          description: "redirect authenticated user to /sign-up/checkout",
          fatal: false,
        });
      }
      navigate("/sign-up/checkout");
    }
  }, [currentUser?.is_authenticated, isFetching]);

  useEffect(() => {
    if (typeof gtag === "function") {
      gtag("event", "add_to_cart");
    }
  }, []);

  const onSubmit: SubmitHandler<SignUpFormValues> = (values) => {
    emailTrigger({ email: values.email })
      .unwrap()
      .then((success) => {
        dispatch(
          emailStepCompleted({
            email: values.email,
            marketingConsent: values.marketingConsent,
          })
        );
        if (success.emailExists) {
          toast.success(
            "An account already exists with this email. Please log in."
          );
          navigate("/login");
        } else {
          if (typeof gtag === "function") {
            gtag("event", "generate_lead");
          }
          navigate("/sign-up/password");
        }
      })
      .catch((error) => {
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "sign-up: onSubmit";
          Sentry.captureException(e);
        });
      });
  };


  return (
    <Layout isCheckout>
      <FormPageWrapper title="Sign Up">
        <p data-cy="step" className="mb-3 sm:mb-6 text-neutral-700">
          STEP 1 OF 3
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            <label
              htmlFor="email"
              className="block mb-2 font-bold text-xl text-neutral-700"
            >
              Enter your email:
            </label>
            <input
              id="email"
              className={`border text-neutral-700 shadow p-3 w-full rounded ${errors?.email ? "border-red-300" : "border-neutral-200"
                }`}
              {...register("email", { required: true, maxLength: 254 })}
              type="email"
            />
            {errors.email?.type === "required" && (
              <FormErrorMessage>Your email is required</FormErrorMessage>
            )}
            {errors.email?.type === "maxLength" && (
              <FormErrorMessage>
                The maximum length is 254 characters
              </FormErrorMessage>
            )}
            <div className="relative flex items-start mt-3">
              <div className="flex items-center h-5">
                <input
                  className="focus:ring-blue-600 transition h-4 w-4 text-blue-700 border-neutral-300 rounded"
                  type="checkbox"
                  {...register("marketingConsent")}
                  id="marketing-consent"
                  aria-describedby="marketing-consent-description"
                />
              </div>
              <div className="ml-2 text-sm">
                <label
                  id="marketing-consent-description"
                  htmlFor="marketing-consent"
                  className="text-neutral-700"
                >
                  Yes! I would like to receive updates and special offers from
                  Giancoli Answers.
                </label>
              </div>
            </div>
          </div>
          <ButtonPrimary size="20" type="submit">
            Next
          </ButtonPrimary>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default SignUp;

export const Head = (props: HeadProps) => {
  const title = "Sign up | Giancoli Answers";

  return <Seo title={title} pathname={props.location.pathname} />;
};
